// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

import React from 'react';
import ReactPlayer from 'react-player'
import {useState, useEffect, useRef} from 'react';

import {Swiper, SwiperSlide, useSwiper} from 'swiper/react';
import {Navigation, Thumbs, Zoom} from 'swiper/modules';
import ContactForm from "./contact";

const PhotoGallery = (props) => {
    if (!props.images) {
        return <>No images</>;
    }

    const [swiper, setSwiper] = useState(null);
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [largeSwiper, setLargeSwiper] = useState(null);

    const [currentSlide, setCurrentSlide] = useState(0);

    const images = props.images;

    const [zoomedImage, setZoomedImage] = useState(null);

    const handleZoomedImage = (image) => {
        setZoomedImage(image);
    }

    const handleClose = (e) => {
        if(e.target === e.currentTarget) {
            setZoomedImage(null);
        }
    }

    useEffect(() => {
        if(largeSwiper) {
            largeSwiper.slideTo(currentSlide);
        }
    }, [currentSlide, largeSwiper]);


    return (
        <>
            <div className={`zoomed-modal ${zoomedImage ? '' : 'd-none'} d-flex justify-content-center align-items-center`} onClick={handleClose}>
                <Swiper
                    pagination={{
                        type: 'fraction',
                        clickable: true,
                    }}
                    navigation={true}
                    loop={true}
                    modules={[Navigation, Thumbs, Zoom]}
                    className="product-slider-xl"
                    thumbs={{swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null}}
                    onSwiper={largeSwiper}
                >
                    {images.map((image, index) => {
                        if (image.t === 'image') {
                            return (
                                <SwiperSlide key={index}>
                                    <div className="swiper-zoom-container">
                                        <img src={image.url} alt={`${index}`} loading={"lazy"}/>
                                    </div>
                                </SwiperSlide>
                            )
                        } else {
                            return (
                                <SwiperSlide key={index}>
                                    <ReactPlayer
                                        url={image.url}
                                        playing={true}
                                        light={image.big_thumbnail}
                                        controls={true}
                                        volume={0.4}
                                        width="100%"
                                        height="80%"
                                        className='video-player object-fit-contain'/>
                                </SwiperSlide>
                            )
                        }
                    })}
                </Swiper>
            </div>
            <Swiper
                pagination={{
                    type: 'fraction',
                    clickable: true,
                }}
                initialSlide={0}
                navigation={true}
                loop={true}
                modules={[Navigation, Thumbs, Zoom]}
                className="product-slider-xl"
                thumbs={{swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null}}
                onSwiper={setSwiper}
                onSlideChange={() => {
                    if(swiper) {
                        setCurrentSlide(swiper.activeIndex);
                    }
                }}
            >
                {images.map((image, index) => {
                    if (image.t === 'image') {
                        return (
                            <SwiperSlide key={index}>
                                <div className="swiper-zoomer" onClick={() => {handleZoomedImage(image.large)}}>
                                    <i className={'bi bi-zoom-in'}></i>
                                </div>
                                <div className="swiper-zoom-container" onClick={() => {handleZoomedImage(image.large)}}>
                                    <img src={image.url} alt={`${index}`} loading={"lazy"}/>
                                </div>
                            </SwiperSlide>
                        )
                    } else {
                        return (
                            <SwiperSlide key={index}>
                                <ReactPlayer
                                    url={image.url}
                                    playing={true}
                                    light={image.big_thumbnail}
                                    controls={true}
                                    volume={0.4}
                                    width="100%"
                                    height="80%"
                                    className='video-player object-fit-contain'/>
                            </SwiperSlide>
                        )
                    }
                })}
            </Swiper>
            {swiper && (
                <Swiper
                    onSwiper={setThumbsSwiper}
                    spaceBetween={10}
                    freeMode={false}
                    threshold={10}
                    watchSlidesProgress={true}
                    modules={[Navigation, Thumbs]}
                    centeredSlides={true}
                    centeredSlidesBounds={true}
                    className={`product-slider-thumbnail-secondary mt-3 ${images.length > 10 ? 'product-slider-thumbnail-secondary--xl' : ''}`}
                    breakpoints={{
                        480: {
                            slidesPerView: 4,
                            slidesPerGroup: 1
                        },
                        768: {
                            slidesPerView: 6,
                            slidesPerGroup: 3
                        },
                        992: {
                            slidesPerView: 7,
                            slidesPerGroup: 3
                        },
                    }}
                >
                    {images.map((image, index) => (
                        <SwiperSlide key={index}>
                            <img src={image.thumb} alt={`${index}`} loading={"lazy"}/>
                        </SwiperSlide>
                    ))}
                </Swiper>
            )}
        </>
    )
}


const ProjectItem = (props) => {
    const details = props.details;
    const closeDiv = () => {
        props.close();
    }
    return (
        <div className="product-wrappa bg-input-dark px-3 py-4 p-lg-4 mb-3">
            <div className="row g-2 g-lg-5">
                <div className="col-12">
                    <div className="item-descrition-card d-flex flex-column mb-3">
                        {details.title}
                    </div>
                    <span className="icon-link icon-link-swiper-close float-end mb-1 icon-projects" onClick={closeDiv}>
                        <i className="bi bi-x ms-1"></i>
                    </span>
                    {props.imageLoading && (
                        <div className="loading-placeholder d-block">
                            {props.lang === 'lt' ? 'Kraunasi...' : 'Loading...'}
                        </div>
                    )}
                    {!props.imageLoading && (
                        <PhotoGallery images={props.images} showZoomedModal={props.showZoomedModal}/>
                    )}
                </div>
            </div>
        </div>
    );
}

const ProductItem = (props) => {
    const details = props.details;

    const closeDiv = () => {
        props.close();
    }

    const showContactForm = () => {
        props.showContactForm();
    }

    return (
        <div className="product-wrappa bg-input-dark px-3 py-4 p-lg-4">
            <div className="row g-2 g-lg-5">
                <div className="col-12 col-lg-6">
                    <span
                        className="item-descrition-card-header d-flex d-lg-none align-items-center justify-content-end"
                        onClick={closeDiv}>
                        <i className="bi bi-x-circle-fill ms-1"></i>
                    </span>
                    <div className="product-slider-thumbnail-wrapper mb-4 mb-lg-0">
                        {props.imageLoading && (
                            <div className="loading-placeholder d-block">
                                {props.lang === 'lt' ? 'Kraunasi...' : 'Loading...'}
                            </div>
                        )}
                        {!props.imageLoading && (
                            <PhotoGallery images={props.images} showZoomedModal={props.showZoomedModal}/>
                        )}
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div className="item-descrition-card d-flex flex-column h-100">
                        <div className="item-descrition-card-header d-flex align-items-center justify-content-between">
                            <h2 className="fw-bold">{details.title}</h2>
                            <span className="icon-link d-none d-lg-block" onClick={closeDiv}>
                                <i className="bi bi-x-lg ms-1"></i>
                            </span>
                        </div>
                        <div className="item-descrition-card-body mt-auto text-body-grey">
                            <div className="mb-4" dangerouslySetInnerHTML={{__html: details.body}}></div>
                            <ul className="item-properties list-unstyled mb-5">
                                {details.table && details.table.map((item, index) => (
                                    <li key={index}>
                                        {item[0]} : <span>{item[1]}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="item-descrition-card-footer">
                            <div>
                                <span
                                    className={`btn btn-primary px-3`}
                                    onClick={showContactForm}
                                    data-bs-toggle="modal"
                                    data-bs-target="#contact-modal">
                                        {props.lang === 'lt' ? 'Siųsti užklausą' : 'Send request'}
                                    <i className="bi bi-chevron-right ms-1"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export { PhotoGallery, ProductItem, ProjectItem }




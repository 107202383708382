import React, { useState } from 'react';

function ContactForm(props) {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const [errors, setErrors] = useState(null);
    const [success, setSuccess] = useState(null);

    // validate email address
    const validateEmail = (email) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    const lang = props.lang;

    let subject = lang === 'lt' ? 'Kontaktų forma' : 'Contact form';

    if(props.subject) {
        subject = props.subject;
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        // Here you would typically send the form data to your server

        if (name.length === 0 || email.length === 0 || message.length === 0) {
            setErrors(lang === 'lt' ? 'Visi laukai yra privalomi': 'All fields are required');
            return;
        }

        if (!validateEmail(email)) {
            setErrors(lang === 'lt' ? 'Neteisingas el. paštas': 'Invalid email address');
            return;
        }

        fetch(`/${props.lang}/api/contact/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': props.csrf,
            },
            body: JSON.stringify({
                name: name,
                email: email,
                message: message,
                subject: subject,
            }),
        }).then(response => {
            if (response.ok) {
                setSuccess(lang === 'lt' ? 'Artimiausiu metu susieksime': 'We will contact you shortly');
                setTimeout(() => {
                    setName('');
                    setEmail('');
                    setMessage('');
                    setSuccess(null);
                }, 5000);
            } else {
                setErrors(lang === 'lt' ? 'Formos siuntimas nepavyko': 'Form submission failed');
            }
        }).catch(error => {
            setErrors(lang === 'lt' ? 'Formos siuntimas nepavyko': 'Form submission failed');
        });

        props.onClose();
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="mb-4">
                <label htmlFor="name" className="form-label">{lang == 'lt' ? 'Vardas': 'Name'} :</label>
                <input id={'name'} className={'form-control'}  placeholder={lang == 'lt' ? 'Vardas': 'Name'} type="text" value={name} onChange={e => setName(e.target.value)} />
            </div>
            <div className="mb-4">
                <label htmlFor="email" className="form-label">{lang == 'lt' ? 'El. paštas': 'E-mail'} :</label>
                <input id={'email'} className={'form-control'}  placeholder={lang == 'lt' ? 'El. paštas': 'E-mail'} type="email" value={email} onChange={e => setEmail(e.target.value)} />
            </div>
            <div className="mb-4">
                <label htmlFor="message" className="form-label">{lang == 'lt' ? 'Žinutė': 'Message'} :</label>
                <textarea id={'message'} className={'form-control'} rows={5} placeholder={lang == 'lt' ? 'Jūsų žinutė': 'Your message'} value={message} onChange={e => setMessage(e.target.value)} />
            </div>
            {errors && <div className="alert alert-danger">{errors}</div>}
            {success && <div className="alert alert-success">{success}</div>}
            <div>
                <button className="w-100 btn btn-primary px-5" type="submit">{lang == 'lt' ? 'Siųsti': 'Send'} <i className="bi bi-chevron-right ms-1"></i></button>
            </div>
        </form>
    );
}

export default ContactForm;
